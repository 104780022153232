import React from 'react';
import { Line } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import chartData from '../../data/chartData.json';
import styles from './styles.module.scss';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

const CommodityChart = ({
	currentTab,
	currentPeriod,
	setCurrentPeriod,
	activeTab,
}) => {
	if (
		!chartData ||
		!chartData[activeTab] ||
		!chartData[activeTab][currentTab][currentPeriod]
	) {
		return <p>Загрузка данных...</p>;
	}
	const data = chartData[activeTab][currentTab][currentPeriod];

	const chartOptions = {
		responsive: true,
		scales: {
			y: {
				beginAtZero: false,
			},
		},
	};

	return (
		<div className={styles.commodityExchangeChart}>
			<h3 className={styles.commodityExchangeChartTitle}>ДИНАМИКА КОТИРОВОК</h3>
			<div className={styles.commodityExchangeChartOptions}>
				{[4, 8].map((period) => (
					<button
						key={period}
						className={`${styles.commodityExchangeChartOption} ${
							currentPeriod === period
								? styles.commodityExchangeChartOptionActive
								: ''
						}`}
						onClick={() => setCurrentPeriod(period)}
					>
						{period} недели
					</button>
				))}
			</div>
			<Line
				data={{
					labels: data.labels,
					datasets: [
						{
							label: 'Цена',
							data: data.data,
							borderColor: '#4CAF50',
							backgroundColor: 'rgba(76, 175, 80, 0.1)',
							tension: 0.1,
						},
					],
				}}
				options={chartOptions}
			/>
		</div>
	);
};

export default CommodityChart;
