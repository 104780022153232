import React from 'react';
import pastCommodityData from '../../data/pastCommodityData.json';
import styles from './styles.module.scss';

const PastCommodityTable = ({ currentTab, activeTab }) => {
	if (
		!pastCommodityData ||
		!pastCommodityData[activeTab] ||
		!pastCommodityData[activeTab][currentTab]
	) {
		return <p>Загрузка данных...</p>;
	}

	const data = pastCommodityData[activeTab][currentTab];

	return (
		<table className={styles.commodityExchangeTable}>
			<thead>
				<tr>
					<th>Товарная позиция</th>
					<th>Изменение цены</th>
					<th>Текущая цена</th>
				</tr>
			</thead>
			<tbody>
				{data.map((item, index) => (
					<tr key={index}>
						<td>{item.name}</td>
						<td
							className={`${styles.commodityExchangePriceChange} ${
								item.change >= 0
									? styles.commodityExchangePriceChangeUp
									: styles.commodityExchangePriceChangeDown
							}`}
						>
							{item.change > 0 ? '+' : ''}
							{item.change.toFixed(2)}%
						</td>
						<td>{item.price.toFixed(2)}</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default PastCommodityTable;
