// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_services__kbPBK {
  margin-bottom: 150px;
}
.styles_servicesGrid__\\+EGfg {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
@media (max-width: 600px) {
  .styles_servicesGrid__\\+EGfg {
    grid-template-columns: 1fr;
  }
}
.styles_servicesItem__cU0VH {
  background-color: #f48020;
  border-radius: 5px;
  padding: 10px;
  min-height: 400px;
  transition: transform 0.2s;
}
.styles_servicesItemIblock__-I7zV {
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 5px;
}
.styles_servicesItemImg__xEhFa {
  object-fit: cover;
  width: 100%;
}
.styles_servicesItem__cU0VH:hover {
  transform: translateY(-5px);
}
.styles_servicesItemTitle__UOwdB {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #fff;
}
.styles_servicesItemText__Jsbo6 {
  font-size: 1rem;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/services/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,oBAAA;AACD;AAAC;EACC,aAAA;EACA,qCAAA;EACA,SAAA;AAEF;AAAE;EALD;IAME,0BAAA;EAGD;AACF;AAAC;EACC,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,iBAAA;EACA,0BAAA;AAEF;AADE;EACC,mBAAA;EACA,gBAAA;EACA,kBAAA;AAGH;AADE;EACC,iBAAA;EACA,WAAA;AAGH;AADE;EACC,2BAAA;AAGH;AAAE;EACC,iBAAA;EACA,mBAAA;EACA,WAAA;AAEH;AACE;EACC,eAAA;EACA,WAAA;AACH","sourcesContent":[".services {\r\n\tmargin-bottom: 150px;\r\n\t&Grid {\r\n\t\tdisplay: grid;\r\n\t\tgrid-template-columns: repeat(3, 1fr);\r\n\t\tgap: 20px;\r\n\r\n\t\t@media (max-width: 600px) {\r\n\t\t\tgrid-template-columns: 1fr;\r\n\t\t}\r\n\t}\r\n\r\n\t&Item {\r\n\t\tbackground-color: #f48020;\r\n\t\tborder-radius: 5px;\r\n\t\tpadding: 10px;\r\n\t\tmin-height: 400px;\r\n\t\ttransition: transform 0.2s;\r\n\t\t&Iblock {\r\n\t\t\tmargin-bottom: 10px;\r\n\t\t\toverflow: hidden;\r\n\t\t\tborder-radius: 5px;\r\n\t\t}\r\n\t\t&Img {\r\n\t\t\tobject-fit: cover;\r\n\t\t\twidth: 100%;\r\n\t\t}\r\n\t\t&:hover {\r\n\t\t\ttransform: translateY(-5px);\r\n\t\t}\r\n\r\n\t\t&Title {\r\n\t\t\tfont-size: 1.2rem;\r\n\t\t\tmargin-bottom: 10px;\r\n\t\t\tcolor: #fff;\r\n\t\t}\r\n\r\n\t\t&Text {\r\n\t\t\tfont-size: 1rem;\r\n\t\t\tcolor: #fff;\r\n\t\t}\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"services": `styles_services__kbPBK`,
	"servicesGrid": `styles_servicesGrid__+EGfg`,
	"servicesItem": `styles_servicesItem__cU0VH`,
	"servicesItemIblock": `styles_servicesItemIblock__-I7zV`,
	"servicesItemImg": `styles_servicesItemImg__xEhFa`,
	"servicesItemTitle": `styles_servicesItemTitle__UOwdB`,
	"servicesItemText": `styles_servicesItemText__Jsbo6`
};
export default ___CSS_LOADER_EXPORT___;
