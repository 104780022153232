import React from 'react';
import styles from './styles.module.scss';

const About = () => {
	return (
		<section className={styles.about} id='about'>
			<div className='container'>
				<h2 className={`${styles.about__title} title`}>О нас</h2>
				<p className={styles.aboutText}>
					Компания EVDE - это ведущая торговая биржа, предоставляющая
					современные и надежные платформы для трейдинга.Мы специализируемся на
					создании удобных и эффективных решений для торговли акциями,
					криптовалютами, товарами и другими финансовыми инструментами.
					<br /> <br />
					Наша миссия - сделать торговлю доступной для всех. Мы обеспечиваем
					высокую ликвидность, безопасность и прозрачность всех операций,
					гарантируя нашим клиентам уверенность и спокойствие.
				</p>
			</div>
		</section>
	);
};

export default About;
