import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles.scss';

const REFRESH_INTERVAL = 300000;

const Table = () => {
	const [trades, setTrades] = useState({
		KGS: [],
		RUB: [],
		EUR: [],
		USD: [],
	});
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const fetchTrades = async () => {
		try {
			setLoading(true);
			const [responseKGS, responseRUB, responseEUR, responseUSD] = await Promise.all([
				axios.get('https://garantex.org/api/v2/trades?market=usdtkgs'),
				axios.get('https://garantex.org/api/v2/trades?market=usdtrub'),
				axios.get('https://garantex.org/api/v2/trades?market=usdteur'),
				axios.get('https://garantex.org/api/v2/trades?market=usdtusd'),
			]);
			setTrades({
				KGS: responseKGS.data,
				RUB: responseRUB.data,
				EUR: responseEUR.data,
				USD: responseUSD.data,
			});
			setLoading(false);
		} catch (error) {
			console.error('Ошибка при получении данных:', error);
			setError('Произошла ошибка при загрузке данных');
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchTrades();
		const intervalId = setInterval(fetchTrades, REFRESH_INTERVAL);
		return () => clearInterval(intervalId);
	}, []);

	if (loading) return <div>Загрузка...</div>;
	if (error) return <div>{error}</div>;
	
	const getLastTradeInfo = (tradesList) => {
		if (tradesList.length > 0) {
		const lastTrade = tradesList[0];
		const originalPrice = parseFloat(lastTrade.price);
		const markedUpPrice = originalPrice * 1.015;
		return {
			price: markedUpPrice.toFixed(3),
			volume: parseFloat(lastTrade.volume).toFixed(2),
		};
		}
		return { price: '-', volume: '-' };
	};

	const tradeInfo = {
		KGS: getLastTradeInfo(trades.KGS),
		RUB: getLastTradeInfo(trades.RUB),
		EUR: getLastTradeInfo(trades.EUR),
		USD: getLastTradeInfo(trades.USD),
	};

	return (
		<div className='table'>
			<div className='container'>
				<div className='table__wrapper'>
					<table className='table__table'>
						<thead>
							<tr>
								<th>Online</th>
								<th>Цена</th>
								<th>Объем</th>
							</tr>
						</thead>
						<tbody>
							{Object.entries(tradeInfo).map(([currency, info]) => (
								<tr key={currency}>
									<td>{currency}/USDT</td>
									<td>{info.price}</td>
									<td>{info.volume}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default Table;
