import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChartLine,
	faMagnifyingGlassChart,
	faShieldHalved,
} from '@fortawesome/free-solid-svg-icons';
import styles from './styles.module.scss';

const Services = () => {
	const [items, setItems] = useState([
		{
			img: 'https://images.unsplash.com/photo-1462556791646-c201b8241a94?q=80&w=2065&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
			title: 'Торговля на бирже',
			text: 'Предоставляем доступ к широкому спектру биржевых товаров. Удобная платформа для покупки и продажи сырьевых ресурсов.',
			icon: faChartLine,
		},
		{
			img: 'https://images.unsplash.com/photo-1462556791646-c201b8241a94?q=80&w=2065&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
			title: 'Аналитика рынка',
			text: 'Ежедневные обзоры и прогнозы рынка. Помогаем принимать информированные решения на основе актуальных данных.',
			icon: faMagnifyingGlassChart,
		},
		{
			img: 'https://images.unsplash.com/photo-1462556791646-c201b8241a94?q=80&w=2065&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
			title: 'Хеджирование рисков',
			text: 'Разрабатываем стратегии для минимизации ценовых рисков. Защищаем ваш бизнес от волатильности рынка.',
			icon: faShieldHalved,
		},
	]);
	return (
		<section className={styles.services}>
			<div className='container'>
				<h2 className={`${styles.servicesTitle} title`}>Услуги</h2>
				<div className={styles.servicesGrid}>
					{items.map((item, index) => (
						<div key={index} className={styles.servicesItem}>
							<div className={styles.servicesItemIblock}>
								<img
									src={item.img}
									alt='services'
									className={styles.servicesItemImg}
								/>
							</div>
							<h3 className={styles.servicesItemTitle}>
								<FontAwesomeIcon icon={item.icon} /> {item.title}
							</h3>
							<p className={styles.servicesItemText}>{item.text}</p>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default Services;
