import React from 'react';
import styles from './styles.module.scss';

const Hero = () => {
	return (
		<section className={styles.hero}>
			<div className='container'>
				<div className={styles.heroContent}>
					<h1 className={styles.heroTitle}>Добро пожаловать в EVDE</h1>
					<p className={styles.heroDescription}>
						EVDE – это инновационная платформа для торговли, предоставляющая
						участникам биржи лучшие условия для успешных сделок
					</p>
					<a href='#about' className={styles.heroButton}>
						Узнать больше
					</a>
				</div>
			</div>
		</section>
	);
};

export default Hero;
