// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_about__SGcea {
  padding-top: 75px;
  margin-top: -75px;
  margin-bottom: 100px;
}
.styles_aboutText__AFCqQ {
  font-size: 1rem;
  color: #fff;
  max-width: 800px;
}`, "",{"version":3,"sources":["webpack://./src/components/about/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;EACA,iBAAA;EACA,oBAAA;AACD;AACC;EACC,eAAA;EACA,WAAA;EACA,gBAAA;AACF","sourcesContent":[".about {\r\n\tpadding-top: 75px;\r\n\tmargin-top: -75px;\r\n\tmargin-bottom: 100px;\r\n\r\n\t&Text {\r\n\t\tfont-size: 1rem;\r\n\t\tcolor: #fff;\r\n\t\tmax-width: 800px;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"about": `styles_about__SGcea`,
	"aboutText": `styles_aboutText__AFCqQ`
};
export default ___CSS_LOADER_EXPORT___;
