import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faIndustry,
	faWheatAwn,
	faTree,
} from '@fortawesome/free-solid-svg-icons';
import CommodityTable from './CommodityTable';
import CommodityChart from './CommodityChart';
import styles from './styles.module.scss';
import PastCommodityTable from './PastCommodityTable';
import PastCommodityChart from './PastCommodityChart';

const CommodityExchange = ({ activeTab }) => {
	const [currentTab, setCurrentTab] = useState('metal');
	const [currentPeriod, setCurrentPeriod] = useState(4);

	const tabs = [
		{ name: 'metal', icon: faIndustry, label: 'Металлопродукция' },
		{ name: 'agro', icon: faWheatAwn, label: 'Сельхозпродукция' },
		{ name: 'wood', icon: faTree, label: 'Лесопродукция' },
	];

	return (
		<>
			<div className={styles.commodityExchange} id='trade'>
				<div className='container'>
					<h1 className={`title ${styles.commodityExchangeTitle}`}>
						Прошедшие Сделки
					</h1>
					<div className={styles.commodityExchangeNav}>
						{tabs.map((tab) => (
							<a
								key={tab.name}
								href='#'
								className={`${styles.commodityExchangeNavItem} ${
									currentTab === tab.name
										? styles.commodityExchangeNavItemActive
										: ''
								}`}
								onClick={(e) => {
									e.preventDefault();
									setCurrentTab(tab.name);
								}}
							>
								<FontAwesomeIcon icon={tab.icon} /> {tab.label}
							</a>
						))}
					</div>
					<div className={styles.commodityExchangeContent}>
						<PastCommodityTable
							currentTab={currentTab}
							activeTab={activeTab}
							styles={styles}
						/>
						<PastCommodityChart
							activeTab={activeTab}
							currentTab={currentTab}
							currentPeriod={currentPeriod}
							setCurrentPeriod={setCurrentPeriod}
							styles={styles}
						/>
					</div>
				</div>
			</div>

			<div className={styles.commodityExchange} id='trade'>
				<div className='container'>
					<h1 className={`title ${styles.commodityExchangeTitle}`}>
						Нынешние Сделки
					</h1>
					<div className={styles.commodityExchangeNav}>
						{tabs.map((tab) => (
							<a
								key={tab.name}
								href='#'
								className={`${styles.commodityExchangeNavItem} ${
									currentTab === tab.name
										? styles.commodityExchangeNavItemActive
										: ''
								}`}
								onClick={(e) => {
									e.preventDefault();
									setCurrentTab(tab.name);
								}}
							>
								<FontAwesomeIcon icon={tab.icon} /> {tab.label}
							</a>
						))}
					</div>
					<div className={styles.commodityExchangeContent}>
						<CommodityTable
							currentTab={currentTab}
							styles={styles}
							activeTab={activeTab}
						/>
						<CommodityChart
							currentTab={currentTab}
							currentPeriod={currentPeriod}
							setCurrentPeriod={setCurrentPeriod}
							styles={styles}
							activeTab={activeTab}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default CommodityExchange;
